<template>
    <div class="title-content">
        <div class="d-flex justify-content-between align-items-center">
            <div class="bewerber-title">
                <h2 class="d-flex align-items-center tincx-h4 text-uppercase response-title pe-2 my-0 response-name-surname-container" :data-tooltip="!selectedItem.public ? 'Diskreter Jobsuchender' : undefined">
                    <span class="response-name">{{ selectedItem.name }}</span>
                    <span class="ms-2 response-verified" data-tooltip="Verifiziert" v-if="selectedItem.verified">
                        <img src="/images/icons/icon-verified.svg" alt="Icon Verifiziert">
                    </span>
                    <img v-if="!selectedItem.public" src="/images/icons/icon-anonym.svg" alt="Anonymes Profil" class="ms-2" width="15">
                </h2>
                <div class="response-beruf-container">
                    <h3 class="response-beruf tincx-h5 text-muted mt-1 mb-0">Jobtitel / Beruf: {{ selectedItem.title }}</h3>
                </div>
            </div>
            <div class="d-flex align-items-center" v-if="false">
                <a href="#" class="me-2 dislike">
                <img src="/images/icons/icon-dislike.svg" alt="Dislike">
                </a>
                <a href="#" class="wishlist mt-1">
                    <svg class="text-primary" xmlns="http://www.w3.org/2000/svg" width="18" height="16.6" viewBox="0 0 18 16.6">
                    <path fill="currentcolor" d="M13.1,0c-1.6,0-3,.7-4.1,1.9C8,.7,6.5,0,5,0,2.2,0,0,2.1,0,4.8c0,0,0,0,0,.1,0,3.4,3.1,6.2,7.7,10.5l1.3,1.2,1.3-1.2c4.6-4.3,7.7-7,7.7-10.5C18.1,2.3,15.9,0,13.2,0c0,0,0,0-.1,0Z"></path>
                    <path class="active-path" d="M9.6,14.2l-.2.2-.4.3-.4-.3-.2-.2c-1.9-1.6-3.7-3.4-5.3-5.3-1-1.1-1.7-2.5-1.7-4,0-1,.4-1.9,1-2.6.7-.7,1.6-1,2.5-1,1.2,0,2.2.5,3,1.4l1.1,1.3,1.1-1.3c.7-.9,1.8-1.4,3-1.4,1,0,1.9.4,2.5,1,.7.7,1,1.6,1,2.6,0,1.5-.7,2.9-1.7,4-1.6,1.9-3.4,3.6-5.3,5.3h0Z"></path>
                    </svg>
                </a>
            </div>
        </div>
        <div class="mt-2 mb-0 my-lg-3 justify-content-between job-info align-items-center">
            <span class="d-flex mb-2 align-items-center response-ort-container" v-if="selectedItem.preferredWorkplaces.length">
                <img width="15" height="17" src="/images/icons/icon-location.svg" alt="Location" class="me-1 d-block"><span class="response-ort tincx-small">{{ selectedItem.preferredWorkplaces.join(', ')}}</span>
            </span>
            <span class="text-ellipsis mb-2 d-flex align-items-center response-arbeitszeitmodell-container" v-if="selectedItem.types.length">
                <img width="14.23" height="14.23" src="/images/icons/icon-time.svg" alt="Job Arbeitszeitmodell" class="me-1 d-block">
                <span class="response-arbeitszeitmodell tincx-small text-ellipsis">{{ selectedItem.types.join(', ')}}</span>
            </span>
            <span class="d-flex mb-2 align-items-center response-language-container" v-if="selectedItem.languages.length">
                <img width="14.6" height="16.4" src="/images/icons/icon-language.svg" alt="Job Language" class="me-1 d-block">
                <span class="response-language tincx-small">{{ selectedItem.languages.join(', ') }}</span>
            </span>
        </div>



        <hr v-if="!selectedItem.public">
        <div class="mt-3 alert alert-warning" v-if="!selectedItem.public">
            Das ist ein <b>diskreter Jobsuchender</b>!<br>
            Die Person hat entschieden, ihre Kontaktdaten nicht öffentlich bekanntzugeben.<br>
            Diskrete Jobsuchende können ebenso kontaktiert werden. Diese können dann entscheiden, ob und wann sie sich zu erkennen geben.
        </div>

    </div>
</template>

<script>
export default {
    name: 'JobCandidateContent',
    props: ['selectedItem'],
    inject: ['$t', '$rtime', '$displayDate'],
}
</script>